import React, {useState} from 'react'
import { CSVReader } from 'react-papaparse'
import UserService from '~/api/services'

const Page = () => {
	const [csvArray, setCsvArray] = useState([]);

	const handleOnDrop = (data) => {
		let buildArr = []

	    if(data && data.length > 1)
	    {
	    	for(let i = 1; i < data.length; i++)
	    	{
	    		let line={}
	    		for(let j = 0; j < data[i].data.length; j++)
	    		{
	    			let newObj = {[data[0].data[j]]: data[i].data[j]}
	    			line = {...line, ...newObj}
	    		}
	    		buildArr.push(line)
	    	}
	    }

	    if(buildArr && buildArr.length > 0)
	    {
	    	setCsvArray(buildArr)
		    UserService.uploadPayloadFileData(buildArr).then(() => console.log('upload successful')).catch(() => {
		    	console.error('error uploading data');
		    })
	    }
	}

	const handleOnError = (err, file, inputElem, reason) => {
	    console.log(err)
	}

	const handleOnRemoveFile = (data) => {
	    console.log(data)
	}

	return(
   	<div className="d-flex flex-column justify-content-start align-items-center pt-4">
		
   		<CSVReader
	        onDrop={handleOnDrop}
	        onError={handleOnError}
	        noDrag
	        addRemoveButton
	        onRemoveFile={handleOnRemoveFile}
	      >
	      <span>Click to upload.</span>
      </CSVReader>

		{csvArray.length>0 ? 
            <div className="p-1 mt-3">
                <table>
                    <thead>
                        <th>UserID</th>
                        <th>Career Title</th>
                        <th>Personal Sales (PS)</th>
                        <th>PS to Goal Description</th>
                        <th>Pie chart percentage (PSB)</th>
                        <th>Personal Sales Commission (PSC)</th>
                        <th>PSC Description</th>
                        <th>Personal Sales Bonus (PSB)</th>
                        <th>PSB Description</th>
                        <th>Estimated Team Sales Commission (TSC)</th>
                        <th>Estimated TSC Description</th>
                        <th>Estimated Additional Bonus</th>
                        <th>Estimated Additional Bonus Description</th>
                        <th>Paid-as Title</th>
                        <th>Monthly Personal Sales Qualification (MPSQ)</th>
                        <th>MPSQ to Goal Description</th>
                        <th>Pie chart percentage (Title MSPQ)</th>
                        <th>Monthly Team Sales Qualification (MTSQ)</th>
                        <th>MTSQ to Goal Description</th>
                        <th>Pie chart percentage (Title MTSQ)</th>
                        <th>Next Title</th>
                    </thead>
                    <tbody>
                        {
                            csvArray.map((entry, i) => (
                            	<tr key={i}>
                                	<td key={entry.userID}>{entry.userID}</td>
                            		<td key={entry.titleCareer}>{entry.titleCareer}</td>
                            		<td key={entry.salesPersonal}>{entry.salesPersonal}</td>
                            		<td key={entry.psGoalDescription}>{entry.psGoalDescription}</td>
                            		<td key={entry.pieChartPercentagePSB}>{entry.pieChartPercentagePSB}</td>
                            		<td key={entry.personalSalesCommission}>{entry.personalSalesCommission}</td>
                            		<td key={entry.pscDescription}>{entry.pscDescription}</td>
                            		<td key={entry.personalSalesBonus}>{entry.personalSalesBonus}</td>
                            		<td key={entry.psbDescription}>{entry.psbDescription}</td>
                            		<td key={entry.estimatedTeamSalesCommission}>{entry.estimatedTeamSalesCommission}</td>
                            		<td key={entry.estimatedTscDescription}>{entry.estimatedTscDescription}</td>
                            		<td key={entry.estimatedAdditionalBonus}>{entry.estimatedAdditionalBonus}</td>
                            		<td key={entry.estimatedAdditionalBonusDescription}>{entry.estimatedAdditionalBonusDescription}</td>
                            		<td key={entry.paidasTitle}>{entry.paidasTitle}</td>
                            		<td key={entry.monthlyPersonalSalesQualification}>{entry.monthlyPersonalSalesQualification}</td>
                            		<td key={entry.mpsqGoalDescription}>{entry.mpsqGoalDescription}</td>
                            		<td key={entry.pieChartPercentageMSPQ}>{entry.pieChartPercentageMSPQ}</td>
                            		<td key={entry.monthlyTeamSalesQualification}>{entry.monthlyTeamSalesQualification}</td>
                            		<td key={entry.mtsqGoalDescription}>{entry.mtsqGoalDescription}</td>
                            		<td key={entry.pieChartPercentageMTSQ}>{entry.pieChartPercentageMTSQ}</td>
                            		<td key={entry.nextTitle}>{entry.nextTitle}</td>
                                </tr>		                               
                            ))
                        }
                    </tbody>
                </table>
            </div> : null
        }
	</div>
	)		
}

export default Page